<template>
    <div class="my-order">
        <transition name="slide">
            <div v-if="showModal" class="confirm-mask">
                <div class="modal">
                    <div class="modal-title">{{$t('PerpetualContract.EditOrder')}}</div>
                    <div class="info-list">
                        <div class="info-item">
                            <div class="label">{{$t('PerpetualContract.TakeProfitPrice')}}:</div>
                            <el-input-number v-model="profitPrice" size="small" :min="0" :step="10"></el-input-number>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('PerpetualContract.StopPrice')}}:</div>
                            <el-input-number v-model="stopPrice" size="small" :min="0" :step="10"></el-input-number>
                        </div>
                    </div>
                    <div class="operate-btns">
                        <el-button class="cancel-btn" @click="closeModal">{{$t('Common.Cancel')}}</el-button>
                        <el-button class="confirm-btn" @click="editOrder">{{$t('PerpetualContract.Edit')}}</el-button>
                    </div>
                </div>
            </div>
        </transition>
        <div class="tab-list">
            <div v-for="(item,index) in tabList" 
            :key="index" 
            :class="['tab-item', curIndex == index ? 'active' : '']" 
            @click="changeCurIndex(index)">
                {{item}}
            </div>
        </div>
        <el-table v-if="curIndex == 0" v-loading="btnLoading" :data="recordList" style="width: 100%;">
            <el-table-column prop="created_at" :label="$t('Trade.Time')">
            </el-table-column>
            <el-table-column :label="$t('Trade.Currency')">
                <template slot-scope="scope">
                    <span>{{scope.row.product.name}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Trade.type')">
                <template slot-scope="scope">
                    <span :style="{ color: scope.row.direction == '1' ? '#01BD8B' : '#FB474E' }">
                        {{scope.row.direction == '1' ? $t('PerpetualContract.OpenLong') : $t('PerpetualContract.OpenShort')}}
                    </span>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="number" label="数量">
            </el-table-column> -->
            <el-table-column :label="$t('PerpetualContract.Margin')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.margin)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.Lever')">
                <template slot-scope="scope">
                    <span>{{scope.row.level}}X</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.HandlingFee')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.service_fee)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.TakeProfitPrice')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.max_price)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.StopPrice')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.stop_price)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.CurrentPrice')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.latest_price)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.OpeningPrice')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.oprice)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.UnsettledProfit')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.unsettled_profit)}}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column>
                <template slot="header" slot-scope="scope">
                    <el-tooltip effect="dark" content="可用 = 开仓量 - 平仓量" placement="top">
                        <div class="available-box">
                            <span>可用</span>
                            <i class="el-icon-warning-outline"></i>
                        </div>
                    </el-tooltip>
                </template>
                <template slot-scope="scope">
                    <span>{{scope.row.number - scope.row.close_number}}</span>
                </template>
            </el-table-column> -->
            <el-table-column :label="$t('PerpetualContract.Operate')">
                <template slot-scope="scope">
                    <div class="btns">
                        <el-button class="btn" @click="Edit(scope.row.id,parseFloat(scope.row.max_price),parseFloat(scope.row.stop_price),parseFloat(scope.row.oprice))">{{$t('PerpetualContract.Edit')}}</el-button>
                        <el-button class="btn" @click="closePosition(scope.row.id)">{{$t('PerpetualContract.ClosePosition')}}</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-table v-if="curIndex == 1" :data="recordList" style="width: 100%;">
            <el-table-column prop="created_at" :label="$t('PerpetualContract.OpenTime')">
            </el-table-column>
            <el-table-column prop="updated_at" :label="$t('PerpetualContract.ClosingTime')">
            </el-table-column>
            <el-table-column :label="$t('Trade.Currency')">
                <template slot-scope="scope">
                    <span>{{scope.row.product.name}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Trade.type')">
                <template slot-scope="scope">
                    <span :style="{ color: scope.row.direction == '1' ? '#01BD8B' : '#FB474E' }">
                        {{scope.row.direction == '1' ? $t('PerpetualContract.OpenLong') : $t('PerpetualContract.OpenShort')}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.Margin')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.margin)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.Lever')">
                <template slot-scope="scope">
                    <span>{{scope.row.level}}X</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.HandlingFee')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.service_fee)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.TakeProfitPrice')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.max_price)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.StopPrice')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.stop_price)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.OpeningPrice')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.oprice)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.ClosingPrice')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.latest_price)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('PerpetualContract.SettlementProfit')">
                <template slot-scope="scope">
                    <span>{{parseFloat(scope.row.profit)}}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="number" label="关闭金额">
            </el-table-column> -->
        </el-table>
        <div v-if="recordList.length > 0" class="pagination-box">
            <el-pagination small @current-change="handleCurrentChange"
                :current-page="currentPage" :pager-count="5"
                :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                :page-size="pageSize" layout="prev, pager, next"
                :total="total" background>
            </el-pagination>
        </div>
        <el-dialog
          :title="$t('PerpetualContract.ClosePosition')"
          :visible.sync="visible"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          width="calc(100% - 3.2rem)"
          top="30vh"
          center
          custom-class="close-dialog-h5"
          @close="close">
          <div class="input-box">
              <el-input v-model="num" type="number" :placeholder="$t('PerpetualContract.EnterCloseQuantity')"></el-input>
              <div class="all" @click="num = allNum">{{$t('Trade.All')}}</div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button :loading="btnLoading" type="primary" @click="closePosition">{{$t('Common.Confirm')}}</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showModal: false,
                profitPrice: 0,
                stopPrice: 0,
                maxStopPrice: 0,
                orderId: '',
                tabList: [this.$t('PerpetualContract.CurrentOrders'), this.$t('PerpetualContract.OrderHistory')],
                curIndex: 0,
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1,
                visible: false,
                num: '',
                btnLoading: false,
                productId: '',
                allNum: 0
            }
        },
        created() {
            this.init()
        },
        mounted() {
            this.$bus.$off('updatePerpetualOrder')
            this.$bus.$on('updatePerpetualOrder', () => {
                this.curIndex = 0
                this.init()
            })
        },
        computed: {
            perpetualOrder() {
                return this.$store.state.perpetualOrder
            },
            
            productList() {
                return this.$store.state.productList
            },
            
            curPrice() {
                return id => {
                    let price = 0
                    this.productList.forEach(item => {
                        if(id == item.id) {
                            price = parseFloat(item.price.close)
                        }
                    })
                    
                    return price
                }
            }
        },
        watch: {
            perpetualOrder(val) {
                if(this.curIndex == 0) {
                    let id_list = JSON.parse(JSON.stringify(val.map(item => item.id)))
                    this.recordList.map((item,index) => {
                        if(id_list.includes(item.id)) {
                            let cur_order = val.find(i => i.id == item.id)
                            item.number = cur_order.number
                            item.margin = cur_order.margin
                            item.service_fee = cur_order.service_fee
							item.latest_price = cur_order.latest_price
                            item.unsettled_profit = cur_order.unsettled_profit
                        } else {
                            this.recordList.splice(index,1)
                        }
                    })
                }
            }
        },
        methods: {
            Edit(id,profit_price,stop_price,open_price) {
                this.orderId = id
                this.profitPrice = profit_price
                this.stopPrice = stop_price
                this.maxStopPrice = open_price
                this.showModal = true
            },
            
            closeModal() {
                this.showModal = false
            },
            
            editOrder() {
                this.showModal = false
                this.btnLoading = true
                this.$api.changeOrderPrice({
                    id: this.orderId,
                    max_limit: this.profitPrice,
                    stop_limit: this.stopPrice
                }).then(res => {
                    this.btnLoading = false
                    this.$message({
                        message: res.message,
                        type: 'success',
                        duration: 1500
                    })
                    this.init()
                }).catch(err => {
                    this.btnLoading = false
                })
            },
            
            changeCurIndex(index) {
                if(this.curIndex != index) {
                    this.curIndex = index
                    this.init()
                }
            },
            
            init() {
                this.recordList = []
                this.currentPage = 1
                this.getRecordList(true)
            },
            
            getRecordList(bool=false) {
                this.$api.perpetualOrderRecord({
                    status: this.curIndex,
                    page: this.currentPage
                }).then(res => {
                    if(bool) {
                        this.pageSize = res.data.per_page
                        this.total = res.data.total
                    }
                    this.recordList = res.data.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getRecordList()
            },
            
            openModal(id,number,close_number) {
                this.productId = id
                this.allNum = number - close_number
                this.visible = true
            },
            
            close() {
                this.num = ''
            },
            
            closePosition(id) {
                this.btnLoading = true
                this.$api.perpetualOrderClose({
                    id
                }).then(res => {
                    this.visible = false
                    this.btnLoading = false
                    this.$message({
                        message: res.message,
                        type: 'success',
                        duration: 1500
                    })
                    this.init()
                    this.$bus.$emit('updatePerpetualBalance')
                }).catch(err => {
                    this.btnLoading = false
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .my-order {
        width: 100%;
        margin-top: 4px;
        
        .confirm-mask {
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 99;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100vh;
            box-sizing: border-box;
            padding: 0 1.6rem;
            background-color: rgba(0, 0, 0, .4);
            
            .modal {
                width: 100%;
                box-sizing: border-box;
                padding: 2rem 2rem 2.4rem;
                border-radius: 8px;
                background-color: #FFFFFF;
                
                .modal-title {
                    width: 100%;
                    margin-bottom: 2rem;
                    font-size: 1.7rem;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .info-list {
                    width: 100%;
                    
                    .info-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        
                        &:not(:last-child) {
                            margin-bottom: 1.4rem;
                        }
                        
                        .label {
                            max-width: 48%;
                            font-size: 1.3rem;
                            color: #6D6F7E;
                            word-wrap: break-word;
                        }
                        
                        .value {
                            max-width: 48%;
                            font-size: 1.4rem;
                            font-weight: bold;
                            color: #000000;
                            word-break: break-all;
                        }
                        
                        /deep/ .el-input--small {
                            font-size: 12px;
                        }
                    }
                }
                
                .operate-btns {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 2.4rem;
                    
                    .cancel-btn {
                        width: 48%;
                        height: 3.6rem;
                        box-sizing: border-box;
                        padding: 0;
                        border: 1px solid #6D6F7E;
                        border-radius: 2px;
                        font-size: 1.3rem;
                        color: #6D6F7E;
                        text-align: center;
                        background-color: #FFFFFF;
                    }
                    
                    .confirm-btn {
                        width: 48%;
                        height: 3.6rem;
                        box-sizing: border-box;
                        padding: 0;
                        border: 0;
                        border-radius: 2px;
                        font-size: 1.3rem;
                        color: #FFFFFF;
                        text-align: center;
                        background-color: #5466EF;
                    }
                }
            }
        }
        
        .tab-list {
            display: flex;
            align-items: center;
            width: 100%;
            
            .tab-item {
                height: 3.5rem;
                line-height: 3.5rem;
                box-sizing: border-box;
                padding: 0 1.2rem;
                font-size: 1.3rem;
                font-weight: bold;
                color: #6D6F7E;
                text-align: center;
                white-space: nowrap;
                background-color: #F2F3F9;
                cursor: pointer;
            }
            
            .active {
                color: #5466EF !important;
                background-color: #FFFFFF !important;
            }
        }
        
        /deep/ .el-table th.is-leaf {
            padding: 8px 0;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 6px;
                font-size: 12px;
                font-weight: normal;
                color: #6D6F7E;
                text-align: center;
            }
        }
        
        /deep/ .el-table td {
            padding: 8px 0;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 6px;
                font-size: 12px;
                color: #000000;
                text-align: center;
            }
        }
        
        /deep/ .el-table__empty-block {
            min-height: 100px;
        
            .el-table__empty-text {
                line-height: 100px;
                font-size: 13px;
            }
        }
        
        /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
            background-color: #F3F5FF;
        }
        
        .available-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            
            .el-icon-warning-outline {
                margin: 0 0 2px 3px;
                font-size: 12px;
                color: #6D6F7E;
            }
        }
        
        .btns {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            
            .btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                min-height: 2.8rem;
                box-sizing: border-box;
                padding: 0.6rem;
                border: 0;
                margin: 0;
                border-radius: 2px;
                font-size: 12px;
                color: #FFFFFF;
                text-align: center;
                word-wrap: break-word;
                background-color: #5466EF;
                cursor: pointer;
                white-space: normal;
                
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
        }
        
        .pagination-box {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 0;
            
            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                background-color: #5466EF;
            }
            
            /deep/ .el-pager li:not(.active):hover {
                color: #5466EF;
            }
        }
    }
</style>